<template>
	<div class="rank">
		<div class="level-pic">
			<img v-if="level<=3" :src="pic">
		</div>
		<el-tooltip :content="title" placement="top" effect="dark">
			<div class="rank-title">
				{{ title }}
			</div>
		</el-tooltip>
		<div class="rank-progress">
			<el-progress :percentage="percentage" :color="color" :show-text="false"/>
		</div>
		<div class="rank-value">
			{{ value }} 次
		</div>
	</div>
</template>

<script>
export default {
	name: 'Rank',
	props: {
		level: {
			type: Number,
			default() {
				return 10;
			}
		},
		title: {
			type: String,
			default() {
				return '';
			}
		},
		value: {
			type: Number,
			default() {
				return 1;
			}
		},
		percentage: {
			type: Number,
			default() {
				return 0;
			}
		}
	},
	data() {
		return {
			level1: require('@/assets/images/main/medal/top1.png'),
			level2: require('@/assets/images/main/medal/top2.png'),
			level3: require('@/assets/images/main/medal/top3.png')
			
		};
	},
	computed: {
		pic() {
			if (this.level == 1) {
				return this.level1;
			}
			if (this.level == 2) {
				return this.level2;
			}
			if (this.level == 3) {
				return this.level3;
			}
		},
		
		color() {
			if (this.level == 1) {
				return '#F98038';
			}
			if (this.level == 2) {
				return '#FFD02E';
			}
			if (this.level == 3) {
				return '#03F6CB';
			}
			return '#36c6fb';
		}
		
	}
	
};
</script>

<style lang="scss" scoped>
.rank {
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	font-size: 14px;
	color: #a1a1a1;
	
	.level-pic {
		width: 20px;
		height: 20px;
		display: flex;
		
		> img {
			width: 13px;
			height: 15px;
			margin: auto auto auto 0;
		}
	}
	
	.rank-title {
		width: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.rank-progress {
		width: calc(100% - 200px);
		margin: 8px;
	}
	
	.rank-value {
		//color: var(--el-main-data-primary);
		color: var(--el-main-data-color-primary);
	}
}

/deep/ .el-progress-bar__outer {
	background-color: #212121 !important;
}
</style>
