<template>
  <div class="history-total">
    <img :src="pic" />
    <div class="content">
      <div>
        <div>{{ GB ? '总采集数据' : '总起飞架次' }}</div>
        <div>
          <span class="value">{{ value }}</span>
          <span>{{ GB ? 'GB' : '架' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryTotal',
  props: {
    GB: {
      type: Boolean,
      default() {
        return true;
      },
    },
    value: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      pic: require('@/assets/images/main/recBgleft.png'),
    };
  },
};
</script>

<style lang="scss" scoped>
.history-total {
  margin: auto;
  width: 154px;
  height: 70px;
  margin-top: 20px;
  background-image: url(~@/assets/images/main/recBg.png);
  position: relative;
  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    font-size: 14px;
    color: var(--color-primary);
    > div {
      margin: auto;
      display: flex;
      flex-direction: column;
      > div {
        margin: auto;
        .value {
          font-size: 24px;
          color: var(--el-main-data-primary);
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
