<template>
  <div style="width: 100%">
    <h1 class="empty">暂无数据！</h1>
  </div>
</template>

<script>
export default {
  name: 'Empty',
  props: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.empty {
  position: absolute;
  margin: auto;
  text-align: center;
  color: var(--color-primary);
  width: 92%;
  top: 44%;
  font-size: 25px;
}
</style>
