<template>
  <div class="sheet-title">
    <div class="title-wrap">
      <div class="point">
        <div />
      </div>
      <div class="title">
        {{ name }}
      </div>
    </div>
    <div class="button-group">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    name: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.sheet-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  .title-wrap {
    display: flex;
    flex-direction: row;
    .point {
      height: initial;
      display: flex;
      width: 45px;
      > div {
        margin: auto;
        margin-left: 10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--el-main-data-primary);
      }
    }
    .title {
      font-weight: 300;
      font-size: 24px;
      color: var(--color-primary);
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
  .button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div:first-child {
      margin-right: 10px !important;
    }
  }
}
</style>
