<template>
  <div class="pipe-chart">
    <div ref="pipe" class="pipe" />
    <div class="list">
      <div>
        <div v-for="(item, index) in data1" :key="'item-pipe-' + index" class="item">
          <div>
            <div v-if="!item.hide" class="pipe-color" :style="{ 'background-color': colors[item.index] }" />
          </div>

          <div>
            <el-tooltip effect="dark" :content="item.name">
              <div class="title">
                {{ item.name }}
              </div>
            </el-tooltip>

            <div v-if="!item.hide">
              <span class="item-count" :style="{ color: colors[index] }">{{ item.value }}</span>
              <span style="font-size: 14px">个</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-for="(item, index) in data2" :key="'item-pipe-' + index" class="item">
          <div>
            <div v-if="!item.hide" class="pipe-color" :style="{ 'background-color': colors[item.index] }" />
          </div>

          <div>
            <el-tooltip effect="dark" :content="item.name">
              <div class="title">
                {{ item.name }}
              </div>
            </el-tooltip>

            <div v-if="!item.hide">
              <span class="item-count" :style="{ color: colors[index] }">{{ item.value }}</span>
              <span style="font-size: 14px">个</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const colors = ['#36C6FB', '#FFD02E', '#F98038', '#96FF00', '#36C6FB', '#6989FC', '#F466A2', '#03F6CB', '#36C6FB', '#87BDF9', '#96FF00', '#36C6FB', '#6989FC'];

export default {
  name: 'PipeChart',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      myChart: null,
      colors,
      option: {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: '5%',
          left: 'center',
          show: false,
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['45%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 0,
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
    };
  },

  watch: {
    data() {
      this.renderOption();
    },
  },
  computed: {
    data1() {
      let arr = [];
      if (this.data.length > 0) {
        if (this.data.length > 1) {
          arr = this.data.slice(0, Math.ceil(this.data.length / 2));
        } else {
          arr = this.data;
        }
      }
      arr.forEach((item, index) => {
        item.index = index;
      });
      return arr;
    },
    data2() {
      let arr = [];
      if (this.data.length > 1) {
        arr = this.data.slice(Math.ceil(this.data.length / 2), this.data.length);
      } else {
        arr = [];
      }
      arr.forEach((item, index) => {
        item.index = index + Math.ceil(this.data.length / 2);
      });
      return arr;
    },
  },

  mounted() {
    this.myChart = this.$echarts.init(this.$refs.pipe);
    this.renderOption();
  },
  methods: {
    renderOption() {
      const data = [];

      this.data.forEach((item, index) => {
        data.push({
          name: item.name,
          value: item.value,
          itemStyle: { color: colors[index] },
        });
      });

      this.option.series[0].data = data;
      this.setOption();
    },
    setOption() {
      try {
        this.myChart.setOption(this.option);
      } catch {
        console.error('error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.pipe-chart {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: row;

  .pipe {
    width: 50%;
    height: 100%;
    margin-left: -1vw;
  }
  .list {
    color: var(--color-primary);
    width: 70%;
    font-size: 16px;
    display: flex;
    position: absolute;
    right: 0;
    flex-wrap: wrap;
    margin-top: 15px;
    justify-content: flex-end;
    max-height: 60%;
    overflow-y: auto;
    .item {
      display: flex;
      flex-direction: row;
      height: 56px;
      margin: auto;
      width: auto;
      // width: auto;
      margin-left: 10px;
      margin-right: 10px;
      > div {
        display: flex;
        flex-direction: column;
        word-break: keep-all;
      }
      .item-count {
        margin-right: 5px;
        font-size: 24px;
        font-weight: bold;
      }
      .pipe-color {
        width: 14px;
        height: 6px;
        background-color: var(--color-primary);
        border-radius: 3px;
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }
}

div {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(148, 148, 148, 0.46);
    border-radius: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #202020;
  }
}
</style>
