<template>
  <div style="margin: 0 auto">
    <button :class="{ 'mian-btn': selected }" @click="handlerClick">
      {{ name }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'MainBtn',
  props: {
    selected: {
      type: Boolean,
      default() {
        return false;
      },
    },
    name: {
      type: String,
      default() {
        return '';
      },
    },
    mode: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    handlerClick() {
      this.$emit('click', { mode: this.mode });
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  padding: 5px 10px;
  border-radius: 3px;
  background-color: var(--el-fill-color-blank);
  color: var(--color-primary);
  text-align: center;
  border: 1px solid var(--main-border-color);
  font-size: 14px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &:active {
    transform: scale(0.9);
  }
}
.mian-btn {
  color: black;
  background-color: var(--el-main-data-primary);
  border: 0;
}
</style>
