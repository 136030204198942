<template>
	<div class="order">
		<div>
			<div>{{ count }}</div>
			<div>{{ title }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Order',
	props: {
		title: {
			type: String,
			default() {
				return '';
			},
		},
		count: {
			type: Number,
			default() {
				return 0;
			},
		},
	},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.order {
	height: 10vh;
	font-size: 15px;
	color: var(--color-primary);
	border: 1px solid var(--main-border-color);
	margin: auto;
	text-align: center;
	display: flex;
	margin-top: 10px;
	
	&:nth-child(4),
	&:nth-child(5),
	&:nth-child(6) {
		margin-top: 20px;
	}
	
	> div {
		margin: auto;
		
		> div:first-child {
			color: var(--el-main-data-color-primary);
			font-size: 30px;
			font-weight: bold;
		}
	}
}
</style>
