<template>
	<div class="device-info">
		<div class="device">
			<span :class="['device-icon', uav ? 'uav' : 'hive']"></span>
		</div>
		<div>
			<div>{{ online }}</div>
			<div class="title">{{ uav ? '在线无人机' : '在线站点' }}</div>
		</div>
		<div>
			<div>{{ total }}</div>
			<div class="title">{{ uav ? '全部无人机' : '全部站点' }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DeviceInfo',
	props: {
		online: {
			type: Number,
			default() {
				return 0;
			},
		},
		total: {
			type: Number,
			default() {
				return 0;
			},
		},
		uav: {
			type: Boolean,
			default() {
				return true;
			},
		},
	},
	data() {
		return {
			uavImg: require('@/assets/images/main/uav/uav.png'),
			siteImg: require('@/assets/images/main/site.png'),
		};
	},
};
</script>

<style lang="scss" scoped>
.device-info {
	display: flex;
	flex-direction: row;
	color: var(--el-main-data-color-primary);
	font-size: 29px;
	
	.device {
		.device-icon {
			display: inline-block;
			width: 80px;
			height: 64px;
			
			&.uav {
				background-image: var(--main-device-uav-img);
				background-repeat: no-repeat;
			}
			
			&.hive {
				width: 78px;
				height: 70px;
				background-image: var(--main-device-site-img);
				background-repeat: no-repeat;
			}
		}
	}
	
	> div {
		margin: auto;
		text-align: center;
		width: 30%;
		
		> .title {
			font-size: 15px;
			color: var(--color-primary);
		}
	}
}
</style>
