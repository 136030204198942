<template>
  <div class="sheet-circle" :style="myStyle">
    <div>
      <el-progress type="circle" :percentage="percentage" :width="width" :color="variables.MainDataPrimary" :stroke-width="8" :show-text="false" />
    </div>
    <div class="text">
      <div :style="width<90?'transform: scale(0.5);':''">
        <div>{{ valueShow }}</div>
        <div>{{ time ? '飞行总时长' : '飞行总里程' }}</div>
        <div>{{ time ? '(h)' : '(KM)' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import themeColor from '@/styles/theme.scss';
export default {
  name: 'CircleSheet',
  props: {
    width: {
      type: Number,
      default() {
        return 120;
      },
    },
    time: {
      type: Boolean,
      default() {
        return true;
      },
    },
    value: {
      type: Number,
      default() {
        return '0';
      },
    },
    percentage: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
   watch: {
    width() {
       this.$nextTick(() => {
        const path = document.getElementsByTagName('path');
        const paths = [];
        for (let i = 0; i < path.length; i += 1) {
          if (path[i]._prevClass === 'el-progress-circle__track') {
            paths.push(path[i]);
          }
        }
        paths.forEach((pa) => {
          pa.setAttribute('stroke', '#1D3B46');
        });
      });
    }
  },
  computed: {

    valueShow(){
      if(this.value<100){
        return this.value.toFixed(2)
      }else{
        return  (Math.round(this.value ))
      }
    },
    myStyle() {
      const wd = this.width + 32;
      return `width:${wd}px;height:${wd}px;`;
    },
    variables() {
      return themeColor;
    },
  },
};
</script>

<style lang="scss" scoped>
.sheet-circle {
  position: relative;
  width: 154px;
  height: 154px;
  background-image: var(--main-sheet-circle-img);
  background-repeat: no-repeat;
  background-size: contain;
  > div {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px;
  }
  .text {
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    position: absolute;
    padding: 0;
    color: var(--color-primary);
    font-size: 14px;
    > div {
      width: auto;
      margin: auto;
      text-align: center;
      > div:first-child {
        color: var(--color-primary);
        font-size: 24px;
      }
    }
  }
}
</style>
