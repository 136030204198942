
<template>
  <div class="color-line">
  </div>
</template>

<script>
export default {
  name: 'DashLine',
  props: {
    name: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {};
  },

};
</script>

<style lang="scss" scoped>
.color-line {
  position: absolute;
  top: 0;
  width: 100%;
  height: 5px;
  background-image: url(~@/assets/images/main/colorBg.png);
  background-size: contain;
}
</style>
