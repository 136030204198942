import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;

// 首页统计数据 查询
function getStatisticList(param) {
  return request({
    url: `${server}/dataCenter/dataStatistics/data?pId=${param.pId}&preDate=${param.preDate}&nowDate=${param.nowDate}`,
    method: 'get',
    param
  })
}


export {
  getStatisticList,

}
