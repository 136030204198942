<template>
  <div class="welcome-wrap" :style="!hideBorder ? 'padding-bottom:32px' : ''">
    <el-container>
      <div class="main-head">
        <img class="logo" :src="pic.logo" v-if="false" />
        <img class="head-bg" src="~@/assets/images/main/head-light.png" />
        <!-- <span class="head-bg"></span> -->
        <div class="head-title-div">
          <div class="head-title">
            <img class="head-title-line-l" :src="pic.lineL" />
            <img class="head-title-img" :src="pic.title" />
            <img class="head-title-line-r" :src="pic.lineR" />
          </div>
        </div>
      </div>

      <div class="left-base realtime-uav">
        <dash-line />
        <div>
          <Title name="实时统计数据" />
        </div>
        <div class="realtime-uav-hive">
          <device-info :uav="true" :online="stat.uav.online" :total="stat.uav.total" />
          <device-info :uav="false" :online="stat.hive.online" :total="stat.hive.total" />
        </div>

        <div class="orders">
          <Order v-for="(item, index) in stat.orders" :key="'order-' + index" :count="item.count" :title="item.title" />
        </div>
      </div>

      <div class="left-base hive-site">
        <dash-line />
        <div>
          <Title :name="distributeByUAV ? '机型分布' : '站点分布'">
            <MainBtn name="机型分布" :selected="distributeByUAV" @click="distributeUAVChange" />
            <MainBtn name="站点分布" :selected="!distributeByUAV" @click="distributeUAVChange" />
          </Title>
          <!-- <div class="select-option" style="width: 170px">
            <MainBtn name="机型分布" :selected="distributeByUAV" @click="distributeUAVChange" />
            <MainBtn name="站点分布" :selected="!distributeByUAV" @click="distributeUAVChange" />
          </div> -->
          <template v-if="pipeChartEmpty">
            <Empty />
          </template>
        </div>

        <div class="pipe-charts">
          <div>
            <PipeChart :data="distributeByUAV ? pipeChartUavData : pipeChartHiveData" />
          </div>
          <div />
        </div>
      </div>

      <div class="sheets center">
        <div class="project">
          <search-bar slot="header" @pId="handleSearchSites" />
        </div>

        <div :class="{ 'select-option-center': true, 'center-time': timeSelectedType == timeTypeEnum.userDefined }">
          <MainBtn name="近一周" :selected="timeSelectedType == timeTypeEnum.lastWeek" @click="handlerTimeTypeClick(timeTypeEnum.lastWeek)" />
          <MainBtn name="近一月" :selected="timeSelectedType == timeTypeEnum.lastMonth" @click="handlerTimeTypeClick(timeTypeEnum.lastMonth)" />
          <MainBtn name="近三月" :selected="timeSelectedType == timeTypeEnum.lastThreeMonth" @click="handlerTimeTypeClick(timeTypeEnum.lastThreeMonth)" />
          <MainBtn name="近一年" :selected="timeSelectedType == timeTypeEnum.lastYear" @click="handlerTimeTypeClick(timeTypeEnum.lastYear)" />
          <MainBtn name="自定义" :selected="timeSelectedType == timeTypeEnum.userDefined" @click="handlerTimeTypeClick(timeTypeEnum.userDefined)" />

          <div class="user-defined-time">
            <el-date-picker v-if="timeSelectedType == timeTypeEnum.userDefined" v-model="userDefinedTime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="timePickHandler" />
          </div>
        </div>
      </div>

      <div class="login">
        <div @click="requestHideBorder" v-if="!isBorderHide">
          <el-tooltip content="隐藏边框" effect="dark">
            <i class="el-icon-s-tools" style="margin-right: 10px"></i>
          </el-tooltip>
        </div>
        <div @click="requestHideBorder" v-else>
          <el-tooltip content="显示边框" effect="dark">
            <i class="el-icon-setting" style="margin-right: 10px"></i>
          </el-tooltip>
        </div>
      </div>

      <div class="right-base history">
        <dash-line />
        <div>
          <Title name="历史统计数据" />
        </div>

        <div class="history-all">
          <CircleSheet :width="myWidth" :time="false" :value="stat.uav.flyTotalMeterKM" :percentage="flyTotalMeterKMPercentage" />
          <CircleSheet :width="myWidth" :value="stat.uav.flyTotalHour" :percentage="flyTotalHourPercentage" />
        </div>
        <div ref="card" class="history-all">
          <HistoryTotal :g-b="false" :value="stat.uav.flightCount" />
          <HistoryTotal :value="stat.uav.flightGB" />
        </div>
      </div>
      <div class="right-base top-list">
        <dash-line />
        <div>
          <Title name="出勤TOP10">
            <MainBtn name="按任务" :selected="attendShowByJob" @click="handlerClick" />
            <MainBtn name="按站点" :selected="!attendShowByJob" @click="handlerClick" />
          </Title>

          <template v-if="attendShowEmpty">
            <Empty />
          </template>
        </div>

        <div class="ranks">
          <Rank v-for="(item, index) in ranksComputed" :key="'rank_' + index" :level="item.level" :title="item.title" :percentage="item.percentage" :value="item.value" />
        </div>
      </div>

      <!-- 机库无人机状态 -->
      <div class="detail-wrap">
        <!-- <HomeUAVState
          v-if="false"
          class="uav-state"
        /> -->
      </div>
      <el-main class="map-wrap">
        <div class="map-contaner">
          <ol-map ref="mainMap" :page="'main'" :allow3D="false" :zoom="4" :maxZoom="16" rightPos="27%" :center="center" @postrender="init" @gotoCenter="gotoCenter" />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import OlMap from '@/components/maps/OlMap.vue';

import DashLine from './children/dashLine.vue';
import Title from './children/title.vue';
import Empty from './children/empty.vue';
import DeviceInfo from './children/deviceInfo.vue';
import Order from './children/order.vue';
import CircleSheet from './children/circleSheet.vue';
import HistoryTotal from './children/historyTotal.vue';
import MainBtn from './children/mainBtn.vue';
import Rank from './children/rank.vue';
import PipeChart from './children/pipeChart.vue';
import SearchBar from './children/SearchBar.vue';
import { getStatisticList } from '@/api/statistics.js';
import socket from '@/utils/socket';
import { getSiteGroup } from '@/api/site';

const timeTypeEnum = {
  lastWeek: 'lastWeek',
  lastMonth: 'lastMonth',
  lastThreeMonth: 'lastThreeMonth',
  lastYear: 'lastYear',
  userDefined: 'userDefined',
};

const head = require('@/assets/images/main/head.png');
const logo = require('@/assets/images/main/logo.png');
const title = require('@/assets/images/main/title.png');
const lineL = require('@/assets/images/main/decorate-left.png');
const lineR = require('@/assets/images/main/decorate-right.png');

export default {
  name: 'Welcome',
  components: {
    OlMap,
    Empty,
    SearchBar,
    Title,
    DashLine,
    DeviceInfo,
    Order,
    CircleSheet,
    HistoryTotal,
    MainBtn,
    Rank,
    PipeChart,
  },
  data() {
    return {
      interval: null,
      socketInterval: null,
      uavMap: new Map(),
      hiveMap: new Map(),
      userSiteSet: new Set(),
      hideBorder: false,
      center: [108, 34],
      preDate: null,
      nowDate: null,
      isBorderHide: false,
      map: null,
      PID: '',
      topic: 'site state',
      socketInstance: null,
      myWidth: 120,

      attendShowByJob: true,
      distributeByUAV: true,
      timeTypeEnum,
      timeSelectedType: timeTypeEnum.lastYear,
      userDefinedTime: '',
      // 需要排序！！todo
      pipeChartUavData: [],
      pipeChartHiveData: [],
      ranks: [],
      siteranks: [],

      projectList: [],
      projectID: '',
      pic: {
        head,
        logo,
        title,
        lineL,
        lineR,
      },

      stat: {
        uav: {
          online: 0, // 在线无人机数量
          total: 0, // 无人机总数
          flyTotalMeterKMAll: 0,
          flyTotalHourAll: 0,
          flyTotalMeterKM: 0, // 飞行总里程，单位： km
          flyTotalHour: 0, // 飞行总时长， h
          flightCount: 0,
          flightGB: 0,
        },
        hive: {
          online: 0, // 在线站点数量
          total: 0, // 站点总数
        },
        orders: [
          {
            title: '本日自动派单数',
            count: 0,
          },
          {
            title: '本日手动派单数',
            count: 0,
          },
          {
            title: '本日等待派单数',
            count: 0,
          },
          {
            title: '正在执飞无人机数',
            count: 0,
          },
          {
            title: '可执飞站点',
            count: 0,
          },
          {
            title: '正在充电站点数',
            count: 0,
          },
        ],
      },
    };
  },

  computed: {
    pipeChartEmpty() {
      if (this.distributeByUAV && this.pipeChartUavData.length === 0) {
        return true;
      }
      if (!this.distributeByUAV && this.pipeChartHiveData.length === 0) {
        return true;
      }
      return false;
    },
    attendShowEmpty() {
      if (this.attendShowByJob && this.ranks.length === 0) {
        return true;
      }
      if (!this.attendShowByJob && this.siteranks.length === 0) {
        return true;
      }
      return false;
    },

    ranksComputed() {
      if (this.attendShowByJob) {
        return this.ranks;
      }
      return this.siteranks;
    },
    flyTotalMeterKMPercentage() {
      if (this.stat?.uav?.flyTotalMeterKM && this.stat?.uav?.flyTotalMeterKMAll) {
        return parseInt((this.stat.uav.flyTotalMeterKM / this.stat.uav.flyTotalMeterKMAll) * 100);
      } else {
        return 0;
      }
    },
    flyTotalHourPercentage() {
      // eslint-disable-next-line radix
      if (this.stat?.uav?.flyTotalHour && this.stat?.uav?.flyTotalHourAll) {
        return parseInt((this.stat.uav.flyTotalHour / this.stat.uav.flyTotalHourAll) * 100);
      } else {
        return 0;
      }
    },
  },
  beforeMount() {},
  mounted() {
    console.log(1);
    this.toggerMainHeadIMG();
    this.map = this.$refs.mainMap;
    // eslint-disable-next-line radix
    this.myWidth = parseInt(this.$refs.card.offsetWidth / 4);
  },

  created() {
    window.addEventListener('resize', this.resizeHandler);
    this.handlerTimeTypeClick(timeTypeEnum.lastYear);
    this.interval = setInterval(() => {
      this.getStatisticList(true);
    }, 5000);
    this.socketInterval = setInterval(() => {
      this.checkStatus();
    }, 30000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
    clearInterval(this.interval);
    clearInterval(this.socketInterval);
    if (this.socketInstance) {
      socket.clearSocket();
      this.socketInstance = null;
    }
  },
  watch: {
    '$store.state.app.border.hide': function (t) {
      this.hideBorder = t;
    },
    userDefinedTime(v) {
      console.log(v);
      if (v) {
        const [date1, date2] = v;
        console.log(arguments);
        this.preDate = date1.getTime();
        this.nowDate = date2.getTime();
        this.getStatisticList();
      } else {
      }
    },
  },
  methods: {
    resizeHandler() {
      // eslint-disable-next-line radix
      this.myWidth = parseInt(this.$refs.card.offsetWidth / 4);
    },
    // 检查报文
    checkStatus() {
      let time = new Date().getTime();
      this.uavMap.forEach((value, key) => {
        if (time - value > 10000) {
          this.$refs.mainMap.removeUavMarkerById(key);
        }
      });
      this.hiveMap.forEach((value, key) => {
        if (time - value > 10000) {
          this.$refs.mainMap.setHiveOnline(key, false);
        }
      });
    },

    toggerMainHeadIMG() {
      const theme = document.body.getAttribute('data-theme-background');
      const MainTitleIMG = document.querySelector('.head-bg');
      MainTitleIMG && (MainTitleIMG.src = theme === 'dark' ? require('@/assets/images/main/head.png') : require('@/assets/images/main/head-light.png'));
    },
    init() {
      this.mainMap = this.$refs.mainMap;
      this.getSiteGroup();
      this.initSocket();
    },
    handleSearchSites(PID) {
      this.PID = PID;
      this.getSiteGroup(PID);
      this.getStatisticList();
    },
    async getSiteGroup(PID) {
      this.userSiteSet.clear();
      this.$refs.mainMap.removeUavAll();
      const { code, data, reason } = PID ? await getSiteGroup(1, 1000, PID) : await getSiteGroup(1, 1000);
      if (data) {
        console.log(data);
        if (data?.list && data.list.length > 0) {
          let pan = false;
          if (PID) {
            pan = true;
            this.$refs.mainMap.removeHives(this.list);
          } else {
            this.list = data.list;
            this.$refs.mainMap.map.getView().setZoom(4);
            this.$refs.mainMap.panTo(...this.center);
            this.list = data.list;
            data.list.forEach((site) => {
              this.userSiteSet.add(site.siteID);
            });
          }
          this.$refs.mainMap.renderHives(data.list, pan);
        }
      } else {
        this.$message.error(reason);
      }
    },
    requestHideBorder() {
      this.isBorderHide = !this.isBorderHide;
      this.$nextTick(() => {
        this.mainMap.updateSize();
      });
      this.$store.dispatch('app/toggleBorder');
    },

    async logout() {
      /* 退出后断开socket连接 */
      socket.initSocket().disconnect();
      /* 清除socket实例 */
      socket.clearSocket();
      this.$store.dispatch('user/logout').then((res) => {
        this.$store.commit('permission/CLEARROUTES', false);
        this.$router.push(`/login?redirect=${this.$route.fullPath}`);
        location.reload();
      });
      //清理路由缓存
    },
    //1027163119
    async getStatisticList(realtime) {
      const param = {
        pId: this.PID,
        preDate: this.preDate,
        nowDate: this.nowDate,
      };

      const { code, reason, data } = await getStatisticList(param);
      if (!realtime) {
        this.ranks = [];
        this.siteranks = [];
        if (data) {
          this.stat.uav.online = data.uavOnlineNum; // 在线无人机数量
          this.stat.uav.total = data.allUavNum; // 无人机总数
          this.stat.hive.online = data.hiveOnlineNum; // 在线无人机数量
          this.stat.hive.total = data.allSiteNum; // 无人机总数
          // eslint-disable-next-line radix
          this.stat.uav.flyTotalMeterKMAll = data.totalMileage;
          // eslint-disable-next-line radix
          this.stat.uav.flyTotalMeterKM = data.totalMileage; // 飞行总里程，单位： km
          // eslint-disable-next-line radix
          this.stat.uav.flyTotalHour = data.totalFlightTime; // 飞行总时长， h
          // eslint-disable-next-line radix
          this.stat.uav.flyTotalHourAll = data.totalFlightTimeAll;
          this.stat.uav.flightCount = data.totalTakeOffNum;
          this.stat.uav.flightGB = data.totalDataCollection ? parseFloat(data.totalDataCollection.toFixed(2)) : 0;

          this.stat.orders[0].count = data.autoMissionNum; // 本日自动派单数
          this.stat.orders[1].count = data.manualMissionNum; // 本日手动派单数
          this.stat.orders[2].count = data.waitMissionNum; // 本日等待派单数
          this.stat.orders[3].count = data.uavFlyNum; // 正在执飞无人机数
          this.stat.orders[4].count = data.hiveFlyNum; // 可执飞站点
          this.stat.orders[5].count = data.chargingHiveNum; // 正在充电站点数

          for (let i = 0; i < data.missionRankingNum.length; i += 1) {
            const rank = {
              level: i + 1,
              title: data.missionRankingNum[i].missionName,
              // eslint-disable-next-line radix
              percentage: parseInt((data.missionRankingNum[i].missionNum / data.missionRankingNum[0].missionNum) * 100),
              value: data.missionRankingNum[i].missionNum,
            };
            this.ranks.push(rank);
          }
          for (let i = 0; i < data.siteRankingNum.length; i += 1) {
            const rank = {
              level: i + 1,
              title: data.siteRankingNum[i].siteName,
              // eslint-disable-next-line radix
              percentage: parseInt((data.siteRankingNum[i].siteNum / data.siteRankingNum[0].siteNum) * 100),
              value: data.siteRankingNum[i].siteNum,
            };
            this.siteranks.push(rank);
          }
          this.pipeChartHiveData = [];
          if (data.hiveModelNum.length > 0) {
            for (let i = 0; i < data.hiveModelNum.length; i += 1) {
              this.pipeChartHiveData.push({
                name: data.hiveModelNum[i].hiveModel,
                value: data.hiveModelNum[i].hiveModelNum,
              });
            }
          }

          this.pipeChartUavData = [];
          if (data.uavModelNum.length > 0) {
            for (let i = 0; i < data.uavModelNum.length; i += 1) {
              this.pipeChartUavData.push({
                name: data.uavModelNum[i].uavModel,
                value: data.uavModelNum[i].uavModelNum,
              });
            }
          }
        } else {
          this.pipeChartUavData = [];
          this.pipeChartHiveData = [];
          this.$message.error(reason);
        }
      } else {
        this.stat.uav.online = data.uavOnlineNum; // 在线无人机数量
        this.stat.uav.total = data.allUavNum; // 无人机总数
        this.stat.hive.online = data.hiveOnlineNum; // 在线无人机数量
        this.stat.hive.total = data.allSiteNum; // 无人机总数

        this.stat.orders[0].count = data.autoMissionNum; // 本日自动派单数
        this.stat.orders[1].count = data.manualMissionNum; // 本日手动派单数
        this.stat.orders[2].count = data.waitMissionNum; // 本日等待派单数
        this.stat.orders[3].count = data.uavFlyNum; // 正在执飞无人机数
        this.stat.orders[4].count = data.hiveFlyNum; // 可执飞站点
        this.stat.orders[5].count = data.chargingHiveNum; // 正在充电站点数
      }
    },

    initSocket() {
      this.socketInstance = socket.initSocket(); // 初始化 socket
      this.socketInstance.on(this.topic, (data) => {
        if (data.STID && !this.userSiteSet.has(data.STID)) {
          return;
        }
        let time = new Date().getTime();
        if (data.type === 1) {
          // 无人机
          if (data.missionBatch) {
            this.uavMap.set(data.UAVID, time);
            this.$refs.mainMap.setUavMarker(data.UAVID, data.longitudeWGS, data.latitudeWGS, data.azimuth);
          } else {
            this.$refs.mainMap.removeUavMarkerById(data.UAVID);
          }
        } else if (data.type === 2) {
          // 机库
          this.hiveMap.set(data.STID, time);

          this.$refs.mainMap.setHiveOnline(data.STID, false);
        }
      });
    },

    gotoCenter(siteID) {
      this.$router.push({ path: '/handleCenter', query: { siteID, from: 'home' } });
    },

    // 选择了其他的时间区间
    timePickHandler() {},
    resetCenter() {
      this.$refs.aMap.resetCenter();
    },
    addZoom(less) {
      this.$refs.aMap.addZoom(less);
    },

    handlerClick() {
      this.attendShowByJob = !this.attendShowByJob;
    },

    handlerTimeTypeClick(t) {
      this.preDate = null;
      this.nowDate = null;
      this.timeSelectedType = t;
      let preDate = null;
      const nowDate = new Date().getTime();
      const oneday = 1 * 24 * 60 * 60 * 1000;
      if (this.timeSelectedType === timeTypeEnum.lastWeek) {
        preDate = nowDate - 7 * oneday;
      } else if (this.timeSelectedType === timeTypeEnum.lastMonth) {
        preDate = nowDate - 30 * oneday;
      } else if (this.timeSelectedType === timeTypeEnum.lastThreeMonth) {
        preDate = nowDate - 90 * oneday;
      } else if (this.timeSelectedType === timeTypeEnum.lastYear) {
        preDate = nowDate - 360 * oneday;
      }
      if (this.timeSelectedType !== timeTypeEnum.userDefined) {
        this.preDate = preDate;
        this.nowDate = nowDate;
        this.getStatisticList();
      }
    },

    distributeUAVChange() {
      this.distributeByUAV = !this.distributeByUAV;
    },
  },
};
</script>

<style lang="scss" scoped>
$titleHight: 120px;
$sheetsTop: 120px;
$sheetWidth: 26%;
$sheetHeight: calc(100% - 180px);
$titleIngHt: 30px;
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 250px !important;
}

.welcome-wrap {
  width: 100%;
  height: 100%;

  .el-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .project-wrap {
      // background: #f40;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 10;
      width: 700px;
      height: 67px;
      overflow: hidden;
      // background-color: #31313199;
      .el-form {
        padding: 16px 0px 0px 10px;

        .el-form-item {
          .el-form-item__label {
            color: var(--el-color-white);
          }

          .el-form-item__content {
            .el-select {
              .el-input {
                .el-input__inner {
                  // background-color: #31313166;
                  // border: 1px solid #444;
                }
              }
            }

            .el-checkbox-group {
              .el-checkbox {
                .el-checkbox__label {
                  color: var(--color-primary);
                }
              }
            }
          }
        }
      }
    }

    .detail-wrap {
      display: none;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      width: 400px;
      overflow: hidden;
    }

    .map-layer {
      position: absolute;
      bottom: 110px;
      left: calc(100% - 690px);
      z-index: 2;
      width: 90px;
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 10px;
      border: 1px solid var(--main-border-color);
      // background-color: #0000008c;
      border-radius: 4px;
    }

    .map-tool {
      position: absolute;
      bottom: 20px;
      left: calc(100% - 570px);
      z-index: 2;
      width: 40px;
      height: auto;
      display: flex;
      flex-direction: column;

      > div {
        width: 32px;
        height: 34px;
        margin: auto;
        display: flex;

        img {
          width: 32px;
          height: 32px;

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }

          &:active {
            transform: scale(0.9);
          }
        }

        i {
          font-size: 25px;
          margin: auto;
          font-weight: bold;
          color: var(--color-primary);

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }

          &:active {
            transform: scale(0.9);
          }
        }
      }
    }

    .main-head {
      width: 100%;
      height: $titleHight;
      position: absolute;
      top: 0;
      z-index: 2;

      .logo {
        position: absolute;
        left: 30px;
        top: 30px;
      }

      .head-bg {
        width: 100%;
        height: 100%;
      }

      .head-title-div {
        width: 100%;
        position: absolute;
        top: 12px;
        left: 0;
        display: flex;

        .head-title {
          margin: auto;

          .head-title-img {
            height: $titleIngHt;
            margin: auto;
          }
        }

        .head-title-line-l {
          margin-right: 20px;
          margin-bottom: 10px;
        }

        .head-title-line-r {
          margin-left: 20px;
          margin-bottom: 10px;
        }
      }
    }

    .select-option {
      position: absolute;
      width: 150px;
      right: 10px;
      top: 20px;
      display: flex;
    }

    .sheets {
      position: absolute;
      width: $sheetWidth;
      height: $sheetHeight;
      top: $sheetsTop;
      z-index: 999;
      overflow: hidden;
      overflow-x: hidden;

      &.left {
        left: 10px;
      }

      &.center {
        width: calc(100% - #{$sheetWidth} - #{$sheetWidth} - 30px) !important;
        left: 28%;
        top: 10%;
        height: auto;
        display: flex;

        .project {
          width: 240px;
          height: 40px;

          form {
            margin-bottom: 20px;
            padding: 0;
            margin-top: -10px;
          }
        }

        .user-defined-time {
          position: absolute;
          right: 6px;
          top: 35px;
        }

        > .select-option-center {
          width: 400px;
          height: 40px;
          top: 0;
          display: flex;
          position: relative;
          overflow: hidden;
        }

        > .center-time {
          height: 70px;
        }
      }

      &.right {
        right: 10px;
        top: 10%;
      }

      .data-block {
        position: relative;
        border: 1px solid var(--main-border-color);
        // background-color: #000000c2;
        padding: 20px 10px;

        .pipe-charts {
          height: 200px;
          overflow-y: auto;
        }

        .ranks {
          padding: 20px;
          padding-left: 10px;
          max-height: 260px;
          overflow-y: auto;
        }
      }

      > .data-block:last-child {
        margin-top: 20px;
      }

      .realtime-uav-hive {
        display: flex;
        flex-direction: column;
      }

      .orders {
        display: flex;
        flex-wrap: wrap;

        > div {
          flex-basis: 7%;
        }
      }
    }
  }

  .right-base {
    position: absolute;
    width: 26%;
    height: auto;
    overflow-y: auto;
    z-index: 100;
    border: 1px solid var(--main-border-color);
    // background-color: #000000c2;
    background-color: var(--el-main-data-block);
    padding: 2.2vh 0.55vw 0 1vw;
    overflow-x: hidden;
    right: 0.5%;
  }

  .history {
    top: 10%;
    height: 37%;
    display: flex;
    flex-direction: column;

    .history-all {
      display: flex;
      margin-top: 1%;

      > div {
        margin: auto;
      }
    }
  }

  .top-list {
    bottom: 2%;
    height: 48%;
    z-index: 200;
    overflow: hidden;

    .pipe-charts {
      height: 200px;
      overflow-y: auto;
    }

    .ranks {
      padding: 1% 0.5%;
      max-height: calc(100% - 20px);
      overflow-y: auto;
    }
  }

  .el-aside {
    padding: 10px;
    background-color: #313131;
  }

  .map-wrap {
    padding: 0px;
    flex: 1;

    .map-contaner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .load-wrap {
      width: 100%;
      height: 100%;
    }

    .WelcomeMap {
      width: 100%;
      height: 100%;
    }
  }
}

.left-base {
  position: absolute;
  width: 26%;
  height: auto;
  overflow-y: auto;
  z-index: 100;
  border: 1px solid var(--main-border-color);
  background-color: var(--el-main-data-block);
  padding: 2.2vh 0.55vw 0 1vw;
  overflow-x: hidden;
  left: 0.5%;
}

.realtime-uav {
  top: 10%;
  height: 55.5%;

  .realtime-uav-hive {
    display: flex;
    flex-direction: column;
  }

  .orders {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex-basis: 30%;
    }
  }
}

.hive-site {
  bottom: 2%;
  height: 30%;
}

div {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(148, 148, 148, 0.46);
    border-radius: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: #202020;
  }
}

.black-theme-center {
  border: 1px solid #6a6a6a;
  background: rgba(30, 30, 30, 0.85) !important;

  .el-select-dropdown__item {
    color: #a1a1a1;

    &:hover,
    &.hover {
      background-color: #363636;
    }

    &.selected {
      color: #36c6fb;
    }
  }

  &[x-placement^='top'] .popper__arrow {
    border-top-color: #6a6a6a;

    &::after {
      border-top-color: rgba(30, 30, 30, 1);
    }
  }
}

.login {
  position: absolute;
  top: 3%;
  right: 1%;
  width: auto;
  height: auto;
  display: flex;
  z-index: 100;
  color: white;

  > div {
    cursor: pointer;
  }
}

/deep/ .popper-class {
  background-color: #18273d;

  .el-input__inner {
    background-color: #18273d;
    border: 0;
    height: 30px;
  }
}
</style>
